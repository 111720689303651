import axiosInstance from "../config/api";
import { makeAutoObservable, runInAction } from "mobx";
interface InstructorResponse {
  data: {
      data: any;
  };
}

interface FeedbackResponse {
  feedbacks: Array<{
    id: string;
    userId: string;
    userName: string;
    image: {
      container: string;
      name: string;
      type: string;
      field: string;
      originalFilename: string;
      size: number;
    };
    verified: boolean;
    content: string;
    rating: number;
    createdAt: string;
  }>;
  count: number;
}
export class CourseStore {
  isNew = false;
  domains = [];
  categories = [];
  instructors = [];
  instructorDetails: any = null;
  courseDetails: any = {};
  filter: any = {
    search: "",
    priceMin: 30,
    priceMax: 1000,
    difficulty: "Intermediate",
    location: null,
    categoryId: "",
  };
  searchedCourses = [];
  byDomainCourses = [];
  topRatedCourses = [];
  upcomingSessions = [];
  recommendedSessions = [];
  subscribedWithCoupon = false;
  selectedCourse: string | null = null;
  courseFeedbacks: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  

  setInitialState() {
    this.isNew = false;
    this.domains = [];
    this.categories = [];
    this.instructors = [];
    this.courseDetails = {};
    this.searchedCourses = [];
    this.byDomainCourses = [];
    this.topRatedCourses = [];
    this.upcomingSessions = [];
    this.recommendedSessions = [];
    this.selectedCourse = null;
    this.instructorDetails = null;
    this.subscribedWithCoupon = false;
    this.courseFeedbacks = {};
  }

  getDomains() {
    axiosInstance
      .get("/Domains")
      .then((response: { data: never[] }) => {
        runInAction(() => {
          this.domains = response.data;
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  getCategories() {
    axiosInstance
      .get("/Categories")
      .then((response: { data: never[] }) => {
        runInAction(() => {
          this.categories = response.data;
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  getInstructors(limit: number) {
    axiosInstance
      .get(`/Profiles/topInstructorsList?limit=${limit}`)
      .then((response: { data: { data: never[] } }) => {
        runInAction(() => {
          this.instructors = response.data.data;
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  getInstructorById(id: string): Promise<InstructorResponse['data']> {
    return axiosInstance
        .get(`/Profiles/instructorDetails/${id}`)
        .then((response: InstructorResponse) => {
            runInAction(() => {
                this.instructorDetails = response.data;
            });
            return response.data; 
        })
        .catch((error: any) => {
            console.log(error);
            throw error; 
        });
}

  async searchCourses(
    limit: number,
    data: {
      search?: string;
      priceMin?: number;
      priceMax?: number;
      selectedDifficulties?: string[];
      difficulty?: string;
      location?: any;
      categoryId?: string;
      domain?: string[];
    }
  ) {
    const content: any = {};
    if (data.search) {
      content.keyword = data.search;
    }
    if (data.categoryId) {
      content.domain = data.categoryId;
    }
    if (data.location && data.location !== "") {
      content.location = data.location;
    }
    if (data.priceMax) {
      content.price = {
        priceMin: data.priceMin,
        priceMax: data.priceMax,
      };
    }
    if (data.selectedDifficulties && data.selectedDifficulties.length > 0) {
      content.selectedDifficulties = data.selectedDifficulties;
    }
    if (data.difficulty) {
      content.difficulty = data.difficulty;
    }
    if (data.domain && data.domain.length > 0) {
      content.domain = data.domain;
    }

    const response = await axiosInstance.get("/Courses/filter", {
      params: {
        limit: limit,
        skip: 0,
        tab: 0,
        content: JSON.stringify(content),
      },
    });
    if (response.status === 200) {
      runInAction(() => {
        this.isNew = true;
        let languageStorage = localStorage.getItem("language") || "English";
        let filteredSearchedCourses = response.data.sessions.filter(
          (course: any) =>
            !course.language || course.language === languageStorage
        );
        this.searchedCourses = filteredSearchedCourses;
        this.filter = {
          search: data.search || "",
          priceMin: data.priceMin || 30,
          priceMax: data.priceMax || 200,
          difficulty: data.difficulty || "Intermediate",
          location: data?.location || null,
          categoryId: data.categoryId || "",
        };
      });
    }
  }

  async lookForSessions(course: string) {
    const token = localStorage.getItem("token");
    const response = await axiosInstance.get(
      `/Courses/lookForSessions/${course}??limit=6&skip=0&filters=%7B%7D&id=${course}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      runInAction(() => {
        this.isNew = true;
        this.searchedCourses = response.data.data.sort((a: any, b: any) => {
          const dateCompare =
            new Date(a.start).getTime() - new Date(b.start).getTime() ||
            new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
          if (dateCompare !== 0) {
            return dateCompare;
          }
          return a.isOnline === b.isOnline ? 0 : a.isOnline ? 1 : -1;
        });
        this.filter = {
          search: "",
          priceMin: 30,
          priceMax: 200,
          difficulty: "Intermediate",
          location: null,
          categoryId: "",
        };
      });
    }
  }

  getByDomainCourses() {
    axiosInstance
      .get(`/Sessions/byDomain`)
      .then((response: { data: never[] }) => {
        runInAction(() => {
          this.byDomainCourses = response.data;
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  getTopRatedCourses(limit: number): Promise<void> {
    return axiosInstance
      .get(`/Courses/topRated?limit=${limit}`)
      .then((response: { data: { data: never[] } }) => {
        runInAction(() => {
          let languageStorage = localStorage.getItem("language") || "English";
          let filteredTopRatedCourses = response.data.data.filter(
            (course: any) => {
              return !course.language || course.language === languageStorage;
            }
          );
          this.topRatedCourses = filteredTopRatedCourses;
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  async getUpcomingSessions() {
    try {
      const response = await axiosInstance.get(
        "/Sessions/upcomingSessions?tab=0"
      );
      runInAction(() => {
        let selectedLanguage = localStorage.getItem("language") || "";

        let languagesListStorage = JSON.parse(
          localStorage.getItem("languagesList") || "[]"
        );

        // Map the selected language (English/French) to the corresponding name in the list
        const languageMapping: Record<string, string> = {
          English: "Anglais",
          French: "Français",
          Arabic: "Arabe",
        };

        const mappedLanguageName =
          languageMapping[selectedLanguage] || selectedLanguage;

        // Find the corresponding language object in the languages list
        const selectedLanguageObject = languagesListStorage.find(
          (lang: any) =>
            lang.name.toLowerCase() === mappedLanguageName.toLowerCase()
        );

        if (!selectedLanguageObject) {
          this.upcomingSessions = [];
          return;
        }
        // Filter sessions to include only those with the selected language
        let filteredUpcomingSessions = response.data.data.filter(
          (session: any) => {
            return session.languageId === selectedLanguageObject.id;
          }
        );
        this.upcomingSessions = filteredUpcomingSessions;
      });
    } catch (error: any) {
      console.error("Error fetching upcoming sessions:", error);
    }
  }

  getRecommendedSessions() {
    const token = localStorage.getItem("token");
    if (token) {
      axiosInstance
        .get(`/Sessions/recommendedSessions?limit=7`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response: { data: { data: never[] } }) => {
          runInAction(() => {
            let languageStorage = localStorage.getItem("language") || "English";
            let filteredRecommendedSessions = response.data.data.filter(
              (session: any) => {
                return (
                  !session.language || session.language === languageStorage
                );
              }
            );
            this.recommendedSessions = filteredRecommendedSessions;
          });
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  // async getCourseById(id: string) {
  //   const response = await axiosInstance
  //     .get(`/Courses/details/${id}`)
  //     .then((response: { status: number; data: any }) => {
  //       if (response.status === 200) {
  //         runInAction(() => {
  //           this.courseDetails = response.data;
  //         });
  //         if (response.data?.tutors?.id) {
  //           this.getInstructorById(response.data.tutors.id);
  //         }
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.log(error);
  //     });
  // }

  async getCourseById(id: string) {
    try {
      const response = await axiosInstance.get(`/Courses/details/${id}`);
      if (response.status === 200) {
        runInAction(() => {
          this.courseDetails = response.data;
        });
        this.getCourseFeedbacks(id);
        if (response.data?.tutors?.id) {
          this.getInstructorById(response.data.tutors.id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getCourseInsideSessionById(id: string) {
    try {
      const response = await axiosInstance.get(`/Courses/details/${id}`);
      if (response.status === 200) {
         return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }


  getCohortById(id: string) {
    axiosInstance
      .get(`/Sessions/details/${id}`)
      .then((response: { status: number; data: any }) => {
        if (response.status === 200) {
          runInAction(() => {
            this.courseDetails = response.data;
          });
          const tutorId: string | undefined = response.data?.tutors?.id;

          if (tutorId) {
            this.getInstructorById(tutorId);
          } else {
            runInAction(() => {
              this.instructorDetails = null;
            });
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  selectCourse(course: string) {
    runInAction(() => {
      this.selectedCourse = course;
    });
  }

  async subscribeWithCoupon(data: {
    code: string;
    studentId: string;
    sessionId: string;
  }): Promise<string | null | undefined> {
    const token = localStorage.getItem("token");
    try {
      const response: any = await axiosInstance.post(
        `Coupons/subscribeWithCoupon?code=${data.code}&studentId=${data.studentId}&sessionId=${data.sessionId}&access_token=${token}`
      );
      if (response?.status === 200 || response?.status === 204) {
        this.subscribedWithCoupon = true;
        return response.data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async payForCourse(data: {
    courseId: string;
    paymentMethod: "PostWire" | "Onsite" | "OnlinePayment";
    profileId: string;
    phoneNumber: string;
    address: string;
    userToken: string;
  }): Promise<boolean> {
    axiosInstance
      .post(
        "TrackingForms",
        {
          address: data.address,
          paymentMethod: data.paymentMethod,
          phone: data.phoneNumber,
          profileId: data.profileId,
          sessionId: data.courseId,
          step: 2,
        },
        {
          headers: {
            Authorization: data.userToken,
          },
        }
      )
      .then((response: { status: number }) => {
        if (response.status === 200) {
          return true;
        }
      })
      .catch((error: any) => {
        console.error(error);
        return false;
      });
    return false;
  }

  async getMyCourses(
    id: string,
    limit: number,
    skip: number,
    tab: number
  ): Promise<any | null> {
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.get(
        `/Courses/getListCourses/${id}?limit=${limit}&skip=${skip}&tab=${tab}&id=${id}&access_token=${token}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getAllDomains(): Promise<any | null> {
    try {
      const response = await axiosInstance.get(`/Domains`);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getAllLanguages(): Promise<any | null> {
    try {
      const response = await axiosInstance.get(`/Languages`);
      console.log("getAllLanguages response:", response);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getAllCategories(): Promise<any | null> {
    try {
      const response = await axiosInstance.get(`/Categories`);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async newCourse(course: object): Promise<any | null> {
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post(
        `/Courses/newCourse?access_token=${token}`,
        course
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async editCourse(course: object): Promise<any | null> {
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.patch(
        `/Courses/editCourse?access_token=${token}`,
        course
      );

      return response.data;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  }

  async addCourseToFavorites(
    courseId: string,
    profileId: string
  ): Promise<any | null> {
    const token = localStorage.getItem("token");
    let body = {
      courseId,
      profileId,
    };
    try {
      const response = await axiosInstance.post(
        `/FavoriteCourses?access_token=${token}`,
        body
      );

      return response.data;
    } catch (error) {
      console.log("errorr", error);
      return null;
    }
  }

  async addSessionToFavorites(
    sessionId: string,
    profileId: string
  ): Promise<any | null> {
    const token = localStorage.getItem("token");
    let body = {
      sessionId,
      profileId,
    };

    try {
      const response = await axiosInstance.post(
        `/FavoriteSessions?access_token=${token}`,
        body
      );

      return response.data;
    } catch (error) {
      console.log("errorr", error);
      return null;
    }
  }

  async deleteCourseFromFavorites(
    favoriteCourseId: string
  ): Promise<any | null> {
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.delete(
        `/FavoriteCourses/${favoriteCourseId}?access_token=${token}`
      );
      return response.data;
    } catch (error) {
      console.log("errorr", error);
      return null;
    }
  }

  async deleteSessionFromFavorites(
    favoriteSessionId: string
  ): Promise<any | null> {
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.delete(
        `/FavoriteSessions/${favoriteSessionId}?access_token=${token}`
      );
      return response.data;
    } catch (error) {
      console.log("errorr", error);
      return null;
    }
  }

  async downloadSyllabus(fileUrl: string): Promise<any | null> {
    try {
      const response = await axiosInstance.get(fileUrl, {
        responseType: "blob",
      });
      return { error: false, data: response };
    } catch (error: any) {
      return {
        error: true,
        data: { code: error.code, message: error.message },
      };
    }
  }

  async getRecentBlogs(): Promise<any | null> {
    try {
      const getBlogsResponse = await axiosInstance.get(
        "https://blog.classx.tech/wp-json/wp/v2/posts"
      );
      const blogs = await getBlogsResponse.data;

      let blogsData = await Promise.all(
        blogs.map(async (blog: any) => {
          const featuredImageResponse = blog.featured_media
            ? await axiosInstance.get(
                `https://blog.classx.tech/wp-json/wp/v2/media/${blog.featured_media}`
              )
            : null;
          const featuredImage = featuredImageResponse
            ? await featuredImageResponse.data
            : null;
          const tagsResponse =
            blog.categories && blog.categories.length > 0
              ? await axiosInstance.get(
                  `https://blog.classx.tech/wp-json/wp/v2/categories?include=${blog.categories.join(
                    ","
                  )}`
                )
              : null;

          const tags = tagsResponse ? await tagsResponse.data : null;

          return {
            id: blog.id,
            title: blog.title.rendered,
            date: blog.date,
            image: featuredImage ? featuredImage.source_url : null,
            tags:
              tags && tags.length > 0 ? tags.map((tag: any) => tag.name) : [],
            link: blog.link,
          };
        })
      );
      if (blogsData && blogsData.length > 4) {
        blogsData = blogsData.splice(0, 4);
      }
      return blogsData;
    } catch (error: any) {
      console.log("errorr", error);
      return null;
    }
  }

  getCourseFeedbacks(courseId: string): Promise<FeedbackResponse['feedbacks']> {
    return axiosInstance
      .get(`/Courses/getFeedbacks?courseId=${courseId}`)
      .then((response: { data: FeedbackResponse }) => {
        return response.data.feedbacks; 
      })
      .catch((error: any) => {
        console.error("Error fetching feedbacks:", error);
        throw error;
      });
  }
}
