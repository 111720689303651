import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../..";
import { useStore } from "../../../../store";
import { formatCurrency } from "../../../../utils/formatCurrency";
import env from "../../../../config/env";
import styles from "./header.module.css";
import { toast } from "react-toastify";

interface IProps {
  type: "course" | "class";
}

function ClassHeader({ type }: IProps) {
  const { courseStore, userStore } = useStore();

  const navigate = useNavigate();
  const [isAddedToFavorites, setIsAddedToFavorites] = useState<boolean>(false);
  const [isWishListClicked, setIsWishListClicked] = useState<boolean>(false);
  let course: any = courseStore.courseDetails;
  const image =
    type === "class" ? course?.SessionImage?.name : course?.image?.name;
  const instructor: any = courseStore.instructorDetails;

  useEffect(() => {
    if (userStore.user) {
      let user = userStore.user;
      let userFavoritesList: any = [];
      if (user.favoriteCourse && user.favoriteCourse.length > 0) {
        userFavoritesList.push(...user.favoriteCourse);
      }
      if (user.favoriteSession && user.favoriteSession.length > 0) {
        userFavoritesList.push(...user.favoriteSession);
      }
      let userFavoritesSessionIds = userFavoritesList.map(
        (userFavorite: any) => {
          return userFavorite.sessionId
            ? userFavorite.sessionId.toString()
            : userFavorite.courseId.toString();
        }
      );

      if (userFavoritesSessionIds && userFavoritesSessionIds.length > 0) {
        if (userFavoritesSessionIds.includes(course?.id)) {
          setIsAddedToFavorites(true);
        }
      }
    }
  }, [userStore.user, course]);
  const [showBubble, setShowBubble] = useState<boolean>(false);
  const [bubbleClicked, setBubbleClicked] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const hasDiscount = course?.newSessionDiscount > 0;
  const discountPrice = hasDiscount
    ? course?.price * (1 - course?.newSessionDiscount / 100)
    : course?.price;

  const addToFavorites = async () => {
    setIsWishListClicked(true);
    if (userStore.user) {
      let response: any;
      type === "class"
        ? (response = await courseStore.addSessionToFavorites(
            course?.id,
            userStore.user.id
          ))
        : (response = await courseStore.addCourseToFavorites(
            course?.id,
            userStore.user.id
          ));
      await userStore.getUser().then(() => {
        setIsAddedToFavorites(true);
        toast.success(
          `You’ve successfully added ${course.title} to your Wishlist.`
        );
        setShowBubble(false);
        setIsWishListClicked(false);
      });
    } else {
      setIsWishListClicked(false);
      setIsOpen(true);
    }
  };

  const deleteFromFavorites = async () => {
    setIsWishListClicked(true);
    if (userStore.user) {
      let favoriteObject =
        type === "class"
          ? userStore.user.favoriteSession.find(
              (favoriteSession: any) =>
                favoriteSession.sessionId.toString() === course.id.toString()
            )
          : userStore.user.favoriteCourse.find(
              (favoriteCourse: any) =>
                favoriteCourse.courseId.toString() === course.id.toString()
            );
      type === "class"
        ? await courseStore.deleteSessionFromFavorites(favoriteObject.id)
        : await courseStore.deleteCourseFromFavorites(favoriteObject.id);
      await userStore.getUser().then(() => {
        setIsAddedToFavorites(false);
        setIsWishListClicked(false);
        if (course.length === 0) {
          setShowBubble(true);
          setBubbleClicked(false);
        }
      });
    } else {
      setIsWishListClicked(false);
      setIsOpen(true);
    }
  };

  return (
    <div className={styles["class-header-container"]}>
      <div className={styles["class-header"]}>
        <img
          alt={course?.title}
          src={
            image ? `${env.IMAGE_API}${image}` : "/images/course-default.png"
          }
          className={styles["class-header-image"]}
        />
        <div className={styles["class-header-details"]}>
          <p className={styles["class-header-title"]}>{course?.title}</p>
          <p className={styles["class-header-instructor"]}>
            {course?.tutors?.fullName}
          </p>

          <div className={styles["class-header-price-container"]}>
            {hasDiscount && course?.price ? (
              <>
                <span className={styles["old-price"]}>
                  {formatCurrency(course?.price, userStore.currency)}
                </span>
                <span className={styles["new-price"]}>
                  {formatCurrency(discountPrice, userStore.currency)}
                </span>
              </>
            ) : (
              <span className={styles["new-price"]}>
                {course?.price
                  ? formatCurrency(course?.price, userStore.currency)
                  : "FREE"}
              </span>
            )}

            {hasDiscount && (
              <span className={styles["discount-badge"]}>
                {course?.newSessionDiscount}% OFF
              </span>
            )}
          </div>
        </div>
        <div className={styles["class-header-wishlist"]}>
          <Button
            type={isAddedToFavorites ? "wishlist_on" : "wishlist_off"}
            width="fit-content"
            icon={
              isAddedToFavorites
                ? "/images/icons/heart-white.svg"
                : "/images/icons/heart.svg"
            }
            onClick={() =>
              isAddedToFavorites ? deleteFromFavorites() : addToFavorites()
            }
            customStyle={{ padding: "10px" }}
            isWishListClicked={isWishListClicked}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(ClassHeader);
