import React from "react";

import styles from "./topics.module.css";
import TopicItem from "./TopicItem";
import { useTranslation } from "react-i18next";

const Topics: React.FC = () => {
  const { t } = useTranslation();

  const topicsItems = [
    {
      title: t(
        "HomepagefeaturedTopics.featuredTopicsList.artificialIntelligence.title"
      ),
      description: t(
        "HomepagefeaturedTopics.featuredTopicsList.artificialIntelligence.description"
      ),
      icon: "/images/icons/topics/ai.svg",
    },
    {
      title: t("HomepagefeaturedTopics.featuredTopicsList.programming.title"),
      description: t(
        "HomepagefeaturedTopics.featuredTopicsList.programming.description"
      ),
      icon: "/images/icons/topics/coding.svg",
    },
    {
      title: t("HomepagefeaturedTopics.featuredTopicsList.design.title"),
      description: t(
        "HomepagefeaturedTopics.featuredTopicsList.design.description"
      ),
      icon: "/images/icons/topics/design.svg",
    },
    {
      title: t("HomepagefeaturedTopics.featuredTopicsList.product.title"),
      description: t(
        "HomepagefeaturedTopics.featuredTopicsList.product.description"
      ),
      icon: "/images/icons/topics/product.svg",
    },
    {
      title: t("HomepagefeaturedTopics.featuredTopicsList.dataScience.title"),
      description: t(
        "HomepagefeaturedTopics.featuredTopicsList.dataScience.description"
      ),
      icon: "/images/icons/topics/data-science.svg",
    },
    {
      title: t("HomepagefeaturedTopics.featuredTopicsList.blockchain.title"),
      description: t(
        "HomepagefeaturedTopics.featuredTopicsList.blockchain.description"
      ),
      icon: "/images/icons/topics/blockchain.svg",
    },
    {
      title: t(
        "HomepagefeaturedTopics.featuredTopicsList.digitalMarketing.title"
      ),
      description: t(
        "HomepagefeaturedTopics.featuredTopicsList.digitalMarketing.description"
      ),
      icon: "/images/icons/topics/digital-marketing.svg",
    },
    {
      title: t(
        "HomepagefeaturedTopics.featuredTopicsList.personalDevelopment.title"
      ),
      description: t(
        "HomepagefeaturedTopics.featuredTopicsList.personalDevelopment.description"
      ),
      icon: "/images/icons/topics/life-skills.svg",
    },
  ];
  return (
    <div className={styles.topics_container}>
      <div className={styles.topics}>
        <div className={styles.topics_heading}>
          <h2 className="text-body-large font-body-large-bold sm:text-subheading sm:font-subheading">
            {t("HomepagefeaturedTopics.featuredTopics")}
          </h2>
          <span className="body-medium">
            {t("HomepagefeaturedTopics.featuredTopicsDescription")}
          </span>
        </div>

        <div className={styles.topics_list}>
          {topicsItems.map((item) => (
            <TopicItem
              key={item.title}
              title={item.title}
              description={item.description}
              icon={item.icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Topics;
