import { useEffect, useState } from "react";

import { useStore } from "../../../store";
import { HomeComponents, Footer, Loading } from "../../../components";
import Container from "src/components/shared/Container";

import styles from "./home.module.css";

export default function Home() {
  const { userStore, courseStore } = useStore();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    courseStore.getDomains();
    courseStore.getCategories();
    // courseStore.getInstructors(4);
    await courseStore.getTopRatedCourses(8);
    await courseStore.getUpcomingSessions();
    // courseStore.getRecommendedSessions();
    if (!userStore.user) {
      await userStore.getUser();
    }
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {" "}
      <HomeComponents.Header />
      <Container>
        <main className={styles.container}>
          <HomeComponents.PopularCourses inSearch={false} />
          <hr />
          <HomeComponents.UpcomingCohorts page="home" />
          <HomeComponents.Partners />
          <HomeComponents.Topics />
          <HomeComponents.Steps />
          <HomeComponents.Feedbacks />
          <HomeComponents.Questions />
          <HomeComponents.BecomeHost />
          <HomeComponents.BecomeTeacher />
          <HomeComponents.Blogs />
          <hr />
          <HomeComponents.BecomeMember />
        </main>
      </Container>
      <Footer />
    </>
  );
}
