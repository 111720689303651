// src/index.tsx
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import router from "./App";
import { StoreProvider } from "./store";
import { LocationStore } from "./store/location";
import Modal from "react-modal";
import { GoogleOAuthProvider } from "@react-oauth/google";
import env from "./config/env";
import "./locales/i18n";
import { BetaNotificationProvider } from "./store/useBetaNotification";
import { CourseStore } from "./store/course";
import { HelmetProvider } from "react-helmet-async";
import { useGoogleMaps } from "./store/useGoogleMaps";
import { Loading } from "./components/shared";

// Initialize stores
const locationStore = new LocationStore();
const courseStore = new CourseStore();

const AppInitializer = ({ children }: { children: React.ReactNode }) => {
  const isMapsLoaded = useGoogleMaps();

  const [loading, setLoading] = useState(true);

  const getLanguagesListAndSave = async () => {
    if (localStorage.getItem("languagesList")) {
      return;
    }

    try {
      const languages = await courseStore.getAllLanguages();
      if (languages?.length > 0) {
        localStorage.setItem("languagesList", JSON.stringify(languages));
      }
    } catch (error) {
      console.error("Error fetching languages list:", error);
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      try {
        await locationStore.initializeLocationData();
        await getLanguagesListAndSave();
      } catch (error) {
        console.error("Error initializing data:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, []);

  if (loading || !isMapsLoaded) {
    return <Loading />;
  }

  return <>{children}</>;
};

Modal.setAppElement("#root");

const googleClientId = env.GOOGLE_CLIENT_ID!;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
      <StoreProvider>
        <BetaNotificationProvider>
          <HelmetProvider>
            <AppInitializer>
              <RouterProvider router={router} />
            </AppInitializer>
          </HelmetProvider>
        </BetaNotificationProvider>
      </StoreProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
