import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../../store";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import styles from "./Reviews.module.css";
import env from "../../../../config/env";
import { format } from "date-fns";

interface CustomDotProps {
  onClick?: () => void;
  active?: boolean;
}

const CustomDot: React.FC<CustomDotProps> = ({ onClick, active }) => {
  return (
    <button
      className={`mb-[50px] h-[3px] rounded-full mx-1.5 transition-all duration-300 ${
        active
          ? "active bg-primary-paradise-pink-800 w-16"
          : "bg-primary-gray-300 w-[60px]"
      }`}
      onClick={onClick}
    />
  );
};

const CustomButtonGroup: React.FC<any> = ({
  next,
  previous,
  goToSlide,
  totalPages,
  currentPage,
  setCurrentPage,
  ...rest
}) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  // console.log(itemsPerPage, totalItems);
  // const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleNext = () => {
    next();
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    else setCurrentPage(1);
  };

  const handlePrevious = () => {
    previous();
    if (currentPage > 1) setCurrentPage(currentPage - 1);
    else setCurrentPage(totalPages);
  };

  return (
    <div className="absolute bottom-0 flex items-center justify-center gap-5 w-full ">
      <img
        className="cursor-pointer"
        onClick={handlePrevious}
        src="/images/icons/arrow-left-purple1.svg"
        alt=""
      />
      <span className="body-small">
        Page {currentPage} of {totalPages}
      </span>
      <img
        className="cursor-pointer"
        onClick={handleNext}
        src="/images/icons/arrow-right-purple1.svg"
        alt=""
      />
    </div>
  );
};

const UserReviews: React.FC = () => {
  const { courseStore } = useStore();
  const course = courseStore.courseDetails;
  const courseId = course?.courseId;
  const [courseData, setCourseData] = React.useState<any>(null);
  const [feedbacks, setFeedbacks] = React.useState<any[]>([]);
  const reviewsToDisplay = feedbacks.length > 0 ? feedbacks : courseData?.testimonials || [];


  useEffect(() => {
    if (courseId) {
      (async () => {
        try {
          const data = await courseStore.getCourseInsideSessionById(courseId);
          setCourseData(data);
        } catch (error) {
          console.error("Error fetching course data:", error);
        }
      })();
    }
  }, [courseId]);

  useEffect(() => {
    if (courseId) {
      courseStore
        .getCourseFeedbacks(courseId)
        .then((feedbacksData: any[]) => {
          setFeedbacks(feedbacksData);
        })
        .catch((error: any) => {
          console.error("Error fetching feedbacks:", error);
        });
    }
  }, [courseId]);

  const reviews = courseData?.testimonials || [];
  console.log("reviews", feedbacks);
  const allReviews = [...feedbacks, ...(courseData?.testimonials || [])];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  };

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = windowWidth < 640 ? 3 : 6;

  const reviewsPage = [];
  if (reviewsToDisplay && reviewsToDisplay.length > 0) {
    for (let i = 0; i < reviewsToDisplay.length; i += itemsPerPage) {
      reviewsPage.push(reviewsToDisplay.slice(i, i + itemsPerPage));
    }
  }

  React.useEffect(() => {
    const handleResize = () => {
      setCurrentPage(1);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [course]);

  const formatDate = (date: string | undefined) => {
    if (!date) return "";
    return format(new Date(date), "dd MMMM, yyyy");
  };

  return (
    <div className="flex flex-col gap-5 sm:gap-[60px] text-primary-purple-850 py-5 sm:py-10 lg:py-[60px]">
      <div className="flex items-center justify-center gap-[50px] sm:gap-14">
        <h2 className="text-body-medium font-body-medium-bold sm:text-subheading sm:font-subheading">
          What others think <br /> about this course
        </h2>
        <div className="w-[3px] h-12 bg-primary-purple-300" />
        <div className="flex flex-col items-center gap-1">
          <div className="flex items-center justify-center gap-3 sm:px-14">
            <img src="/images/icons/stars-yellow.svg" />
            <h1 className="text-subheading font-subheading sm:text-heading-large sm:font-extrabold">
              5.0
            </h1>
          </div>
          <h4 className="text-caption-text font-caption-text-bold sm:text-body-small sm:font-body-small-bold">
            Average Rating
          </h4>
        </div>
      </div>
      <div className="relative flex flex-col sm:gap-5">
        <Carousel
          responsive={responsive}
          showDots
          arrows={false}
          customDot={<CustomDot />}
          renderDotsOutside
          customButtonGroup={
            <CustomButtonGroup
              totalPages={reviewsPage.length || 1}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          }
          renderButtonGroupOutside
        >
          {reviewsPage.map((reviews, i) => (
            <div
              key={i}
              className="flex flex-wrap gap-5 px-3 mb-10 text-primary-gray-900"
            >
              {reviews.map((review: any, index: any) => (
                <div key={index} className={styles.review_card}>
                  <div className="flex flex-col gap-5 sm:gap-6 p-5 sm:p- bg-primary-gray-50 rounded-xl shadow-[0px_18px_26px_-4px_rgba(0,0,0,0.05)]">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-1">
                        {Array.from(
                          { length: Math.floor(review?.rating || 5) },
                          (_, index) => (
                            <img
                              key={index}
                              width={16}
                              height={16}
                              alt="Star Icon"
                              src="/images/icons/star-yellow.svg"
                            />
                          )
                        )}
                        {Array.from(
                          { length: 5 - Math.floor(review?.rating || 5) },
                          (_, index) => (
                            <img
                              key={index}
                              width={16}
                              height={16}
                              alt="Star Icon"
                              src="/images/icons/star-gray.svg"
                            />
                          )
                        )}
                      </div>
                      <span className="caption-text">
                        {formatDate(review?.createdAt)}
                      </span>
                    </div>
                    <div className="flex flex-col gap-1">
                      {review?.title && (
                        <span className="body-small-bold">{review?.title}</span>
                      )}
                      <p className="caption-text">
                        {review?.content || review?.feedback}
                      </p>
                    </div>
                    <div className="flex items-center gap-2.5">
                      <img
                        alt=""
                        className="object-cover rounded-full size-[48px]"
                        src={
                          review?.image?.name
                            ? `${env.IMAGE_API}${review.image.name}`
                            : review?.photo?.container?.name
                            ? `${env.IMAGE_API}${review.photo.container.name}`
                            : "/images/default-user.svg"
                        }
                      />
                      <div>
                        <span className="body-small-bold">
                          {review?.userName || review?.author}
                        </span>
                        <div className="flex items-center gap-1">
                          <img
                            alt=""
                            src="/images/icons/success-green.svg"
                            width={16}
                            height={16}
                          />
                          <span className="caption-text text-tertiary-success-700">
                            {review?.verified ? "Verified Student" : "Student"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </Carousel>
        <div className="h-[54px]" />
      </div>
    </div>
  );
};

export default UserReviews;
