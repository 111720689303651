import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useStore } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { ReactComponent as Subheading } from "./Subheading.svg";
import { ReactComponent as GoogleMeet } from "./Google Meet.svg";
import { ReactComponent as Teams } from "./Teams.svg";
import { ReactComponent as Zoom } from "./Zoom.svg";

import Carousel from "react-multi-carousel";
import "./index.css";
import { toJS } from "mobx";
import env from "../../../../config/env";


const isValidOnlineLink = (link: any) => {
  if (!link) return false;
  
  // Zoom patterns
  const zoomPatterns = [
   /^https?:\/\/on\.zoom\.us/,
      /^on\.zoom\.us/,
      /^https?:\/\/us04web\.zoom\.us/,
      /^us04web\.zoom\.us/,
      /^https?:\/\/app\.zoom\.us/,
      /^app\.zoom\.us/
  ];
  
  // Google Meet patterns
  const googleMeetPatterns = [
    /^https?:\/\/meet\.google\.com/,
    /^meet\.google\.com/
  ];
  
  // Teams patterns
  const teamsPatterns = [
    /^https?:\/\/teams\.microsoft\.com/,
    /^teams\.microsoft\.com/
  ];
  
  return (
    zoomPatterns.some(pattern => pattern.test(link)) ||
    googleMeetPatterns.some(pattern => pattern.test(link)) ||
    teamsPatterns.some(pattern => pattern.test(link))
  );
};


function parseLocation(address: string): string {
  if (!address) return "";
  const parts = address
    .split(",")
    .map((part) => part.trim())
    .filter(Boolean);

  return parts.slice(-3).join(", ");
}

function ClassUpcomingCohorts() {
  const navigate = useNavigate();
  const { userStore, courseStore } = useStore();

  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState<any[]>([]);
  const [courses, setCourses] = useState<any[]>([]);
  const [showOnlineIndicator, setShowOnlineIndicator] = useState(false);

  useEffect(() => {
    setCourses(courseStore.upcomingSessions);
    setSelectedCourse(0);
  }, [courseStore]);

  const [selectedCourse, setSelectedCourse] = useState<any>(0);
  const [selectedSession, setSelectedSession] = useState<any>(null);

  const rating = courses[selectedCourse]?.rating || 0;

  const totalStars = 5;
  const filledStars = Math.floor(rating);
  const emptyStars = totalStars - filledStars;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const stars = [
    ...new Array(filledStars).fill("filled"),
    ...new Array(emptyStars).fill("empty"),
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3, // Show 3 sessions on desktop
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 2, // Show 2 sessions on tablet
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1, // Show 1 session on mobile
      partialVisibilityGutter: 20,
    },
  };

  const user: any = userStore.user;
  const course: any = courseStore.courseDetails;
  const courseId = course?.courseId;

  useEffect(() => {
    if (courseId) {
      setLoading(true);
      courseStore.lookForSessions(courseId);
    }
  }, [courseId, courseStore]);

  useEffect(() => {
    const fetchedSessions = toJS(courseStore.searchedCourses);

    if (fetchedSessions.length > 0) {
      setSessions(fetchedSessions);
    } else {
      setSessions([]);
    }
    setLoading(false);
  }, [courseStore.searchedCourses]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (loading) {
        setLoading(false);
      }
    }, 5000);
    return () => clearTimeout(timeout);
  }, [loading]);

  useEffect(() => {
    if (sessions.length > 0 && !selectedSession) {
      setSelectedSession(sessions[0]);
    }
  }, [sessions]);

  const participate = () => {
    if (userStore.user) {
      navigate(`/payment/${selectedSession?.id}`);
    } else {
      setIsOpen(true);
    }
  };

  const itemsOnDesktop = responsive.desktop.items;
  const placeholdersCount = Math.max(0, itemsOnDesktop - sessions.length);
  const placeholders = new Array(placeholdersCount).fill(null).map((_, i) => ({
    id: `placeholder-${i}`,
    isPlaceholder: true,
  }));

  const finalCarouselItems = [...sessions, ...placeholders];
  return (
    <div className="container">
      {/* <div className="flex justify-center">
        <Subheading />
      </div> */}
      <div className="carouselContainer">
        <div>
          {loading ? (
            <div className="flex justify-center items-center py-10">
              <div className="loader"></div>
            </div>
          ) : sessions.length === 0 ? (
            <p className="text-center text-gray-500">
              No upcoming sessions available.
            </p>
          ) : (
            <Carousel
              swipeable
              draggable
              responsive={responsive}
              ssr={true}
              infinite={true}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carouselContainer"
              // removeArrowOnDeviceType={["tablet", "mobile"]}
            >
              {finalCarouselItems.map((session, index) => {
                if (session.isPlaceholder) {
                  // RENDER A PLACEHOLDER CARD
                  return (
                    <div
                      key={session.id || index}
                      className="carouselItem placeholder"
                    ></div>
                  );
                } else {
                  // RENDER A REAL SESSION
                  return (
                    <div
                      key={session?.id || session?.title || index}
                      className="carouselItem"
                      onClick={() => {
                        setSelectedSession(session);
                        setShowOnlineIndicator(session?.isOnline || false);
                      }}
                    >
                      <div className="flex justify-between items-center w-full">
                        <span className="font-inter text-[14px] font-bold leading-[24px] text-[#1B0209] flex items-center pr-1">
                          <img
                            src="/images/icons/location-purple.svg"
                            alt=""
                            className="w-4 h-4 mr-1"
                          />
                          {session?.isOnline
                            ? "Online"
                            : parseLocation(
                                session?.sessionBooking?.[0]?.bookingRoom
                                  ?.address?.name || ""
                              )}
                        </span>
                        {session?.newSessionDiscount > 0 && (
                          <span className="bg-primary-paradise-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full min-w-[73px] min-h-[24px]">
                            {session?.newSessionDiscount}% OFF
                          </span>
                        )}
                      </div>
                      <span className="font-inter text-[22px] font-semibold leading-[32px] text-[#1B0209] mt-2 max-h-[20px] date-text sessionDate">
                        {format(
                          new Date(session.start),
                          "dd MMM"
                        ).toUpperCase()}{" "}
                        →{" "}
                        {format(new Date(session.end), "dd MMM").toUpperCase()}
                      </span>
                    </div>
                  );
                }
              })}
            </Carousel>
          )}
        </div>
      </div>

      {sessions.length > 0 &&
  selectedSession &&
  !(
    selectedSession?.isOnline &&
    !isValidOnlineLink(selectedSession.onlineSessionLink)
  ) && (
    <div
      className={`bg-white rounded-3xl border border-gray-200 p-5 gap-5 flex flex-col lg:flex-row md:flex-col sm:flex-col items-center mx-auto details-container ${
        selectedSession?.isOnline
          ? "w-[1200px] max-h-[120px] lg:max-w-[1200px] lg:max-h-[120px] md:max-w-[800px] md:max-h-[120px] sm:max-w-[350px] sm-max-h-[120px] bg-[#FAFAFA]"
          : "max-w-[1200px] max-h-[520px] bg-white"
      }`}
    >
      {selectedSession?.isOnline && isValidOnlineLink(selectedSession.onlineSessionLink) ? (
        <div className="w-full h-full flex flex-col items-center justify-center p-5">
          <div className="flex items-center gap-4">
            <div className="w-[80px] h-[80px]">
              {(() => {
                const link = selectedSession.onlineSessionLink;
                if (link.includes('meet.google.com')) {
                  return (
                    <div
                      onClick={() => window.open(link, "")}
                      className="w-full h-full cursor-pointer"
                    >
                      <GoogleMeet className="w-full h-full" />
                    </div>
                  );
                } else if (link.includes('zoom.us')) {
                  return (
                    <div
                      onClick={() => window.open(link, "")}
                      className="w-full h-full cursor-pointer"
                    >
                      <Zoom className="w-full h-full" />
                    </div>
                  );
                } else if (link.includes('teams.microsoft.com')) {
                  return (
                    <div
                      onClick={() => window.open(link, "")}
                      className="w-full h-full cursor-pointer"
                    >
                      <Teams className="w-full h-full" />
                    </div>
                  );
                }
                return (
                  <div className="w-full h-full">
                    <GoogleMeet className="w-full h-full" />
                  </div>
                );
              })()}
            </div>
            <div className="flex flex-col items-start">
              <span className="font-inter text-lg font-semibold text-[#0D0D0D]">
                Online Class
              </span>
              <span className="font-inter text-[14px] font-normal leading-[24px] text-[#3F3F3E]">
                Session Hosted At{" "}
                {(() => {
                  const link = selectedSession.onlineSessionLink;
                  if (link.includes('teams.microsoft.com')) return 'Microsoft Teams';
                  if (link.includes('zoom.us')) return 'Zoom';
                  if (link.includes('meet.google.com')) return 'Google Meet';
                  return '';
                })()}
              </span>
            </div>
          </div>
        </div>
      ) : null}

            {!selectedSession?.isOnline && (
              <div className="w-full min-w-[561px]  lg:w-2/4 gap-1/8 flex items-center">
                <div className="w-full lg:w-2/3 lg:pr-2 md:max-w-[376px] sm:max-w-[334px]">
                  <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlay
                    autoPlaySpeed={3000}
                    infinite
                    draggable
                    keyBoardControl
                    showDots
                    responsive={{
                      desktop: {
                        breakpoint: { max: 3000, min: 1024 },
                        items: 1,
                      },
                      tablet: { breakpoint: { max: 1024, min: 640 }, items: 1 },
                      mobile: { breakpoint: { max: 640, min: 0 }, items: 1 },
                    }}
                    slidesToSlide={1}
                    swipeable
                  >
                    {[
                      selectedSession?.sessionBooking?.[0]?.bookingRoom?.logo,
                      ...(selectedSession?.sessionBooking?.[0]?.bookingRoom
                        ?.album || []),
                    ].map((image, index) =>
                      image ? (
                        <img
                          key={index}
                          src={`${env.IMAGE_API}${image?.name}`}
                          alt={`Room ${index + 1}`}
                          className="big-image object-cover rounded-lg mx-auto w-[334px] h-[178px] md:w-[376px] md:h-[238px] sm:w-[334px] sm:h-[178px]"
                        />
                      ) : null
                    )}
                  </Carousel>
                </div>
                <div className="w-full flex flex-col gap-3 small-images">
                  {[
                    selectedSession?.sessionBooking?.[0]?.bookingRoom?.logo,
                    ...(selectedSession?.sessionBooking?.[0]?.bookingRoom
                      ?.album || []),
                  ]
                    .slice(1, 3)
                    .map((image, index) =>
                      image ? (
                        <img
                          key={index}
                          src={`${env.IMAGE_API}${image?.name}`}
                          alt={`Classroom ${index + 1}`}
                          className="max-w-[175px] max-h-[114px] object-cover rounded-lg"
                        />
                      ) : null
                    )}
                </div>
              </div>
            )}
            {!selectedSession?.isOnline && (
              <div className="w-full lg:w-full flex flex-col display-phone">
                <span className="font-inter text-[14px] font-bold leading-[24px] text-[#1B0209] flex items-center pr-1 line-clamp-1 location-text">
                  <img
                    src="/images/icons/location-purple.svg"
                    alt=""
                    className="w-4 h-4 mr-1"
                  />
                  {selectedSession?.sessionBooking?.[0]?.bookingRoom?.address
                    ?.name || ""}
                </span>
                <h3 className="font-inter text-[22px] font-semibold leading-[32px] text-[#1B0209]">
                  {selectedSession?.sessionBooking?.[0]?.bookingRoom?.name ||
                    "No Room Assigned"}
                </h3>
                <div className="flex items-center gap-1">
                  {stars.map((starType, idx) => (
                    <img
                      key={idx}
                      width={16}
                      height={16}
                      src={`/images/icons/${
                        starType === "filled" ? "star.svg" : "empty-star.svg"
                      }`}
                      alt={starType}
                    />
                  ))}
                  <span className="font-semibold">
                    {selectedSession?.rating || "4.3"}
                  </span>
                  <span className="text-gray-500 text-sm">
                    ({selectedSession?.ratingsCount || "26"})
                  </span>
                </div>
                <p className="font-inter text-[14px] font-normal leading-[24px] text-[#1B0209] mt-2 line-clamp-3">
                  {selectedSession?.sessionBooking?.[0]?.bookingRoom
                    ?.description || "No description available."}
                </p>
                <div className="flex items-center justify-center mt-4 w-full btn-container">
                  <button
                    onClick={participate}
                    className="bg-[#0A0003] text-white py-2 rounded-lg hover:bg-gray-900 transition details-btn lg:w-[420px]"
                  >
                    Book a Seat
                  </button>
                  <span className="flex items-center gap-1 text-primary-paradise-pink-500 font-inter text-[12px] font-semibold leading-[24px] mb-2 p-1">
                    <img
                      src="/images/icons/learners.svg"
                      alt="Learners Icon"
                      className="w-5 h-5"
                    />
                    {selectedSession?.subscribedNumber || "12"} students
                    enrolled
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
    </div>
  );
}

export default observer(ClassUpcomingCohorts);
