import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Input, Select } from "../../../../components";
import { validateEmail, validateName } from "../../../../utils/validation";
import { useStore } from "../../../../store";
import PaymentMethods from "./PaymentMethods";
import styles from "./form.module.css";
import { useTranslation } from "react-i18next";

function ProgressItem({
  count,
  text,
  active,
}: {
  count: number;
  text: string;
  active?: boolean;
}) {
  return (
    <div className={styles.progress_item}>
      <div
        className={
          active
            ? styles.progress_item_count_active
            : styles.progress_item_count
        }
      >
        {active && <span>{count}</span>}
      </div>
      <span className={styles.progress_item_text}>{text}</span>
    </div>
  );
}

export default function PaymentForm({
  status,
  paymentMethod,
  submitRef,
  agree,
  setPaymentMethod,
  setStatus,
  courseTitle,
}: {
  courseTitle: string;
  status: 1 | 2 | 3;
  paymentMethod:
    | "PostWire"
    | "Onsite"
    | "OnlinePayment"
    | "KonnectPayment"
    | "StripePayment";
  submitRef: React.RefObject<HTMLButtonElement>;
  agree: boolean;
  setPaymentMethod: React.Dispatch<
    React.SetStateAction<
      | "PostWire"
      | "Onsite"
      | "OnlinePayment"
      | "KonnectPayment"
      | "StripePayment"
    >
  >;
  setStatus: React.Dispatch<React.SetStateAction<1 | 2>>;
}) {
  const navigate = useNavigate();
  const { userStore } = useStore();
  const { t } = useTranslation();

  const [friendAdded, setFriendAdded] = React.useState<boolean>(false);
  const [save, setSave] = React.useState<boolean>(false);
  const [friendSave, setFriendSave] = React.useState<boolean>(false);
  const [friendFirstName, setFriendFirstName] = React.useState<string>("");
  const [friendLastName, setFriendLastName] = React.useState<string>("");
  const [fullName, setFullName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [friendEmail, setFriendEmail] = React.useState<string>("");
  const [streetAddress, setStreetAddress] = React.useState<string>("");
  const [friendStreetAddress, setFriendStreetAddress] =
    React.useState<string>("");
  const [city, setCity] = React.useState<string>("");
  const [friendCity, setFriendCity] = React.useState<string>("");
  const [zipCode, setZipCode] = React.useState<string>("");
  const [friendZipCode, setFriendZipCode] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [friendPhone, setFriendPhone] = React.useState<string>("");

  const [countryList, setCountryList] = React.useState([]);

  const [country, setCountry] = React.useState<string>("");
  const [friendCountry, setFriendCountry] = React.useState<string>("");

  const [countryState, setCountryState] = React.useState<string>("");
  const [friendCountryState, setFriendCountryState] =
    React.useState<string>("");

  const [stateList, setStateList] = React.useState([]);
  const [friendStateList, setFriendStateList] = React.useState([]);

  const [isFirstEffectDone, setIsFirstEffectDone] = React.useState(false);
  const [user, setUser] = React.useState<any | null>(null);

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("/login");
      } else {
        setUser(user);
      }
      setIsFirstEffectDone(true);
    });
  }, []);

  useEffect(() => {
    const countriesFromLocalStorage = localStorage.getItem("countries");
    if (countriesFromLocalStorage) {
      const countries = JSON.parse(countriesFromLocalStorage).map(
        (country: any) => {
          return { value: country.c_id, label: country.name };
        }
      );

      setCountryList(countries);
      if (user?.billingInfo) {
        setFullName(user?.billingInfo?.fullName);
        setEmail(user?.billingInfo?.email);
        setPhone(user?.billingInfo?.phone);
        setCountry(user?.billingInfo?.country);
        onCountryChange(user?.billingInfo?.country, countries);
        setCountryState(user?.billingInfo?.state);
        setStreetAddress(user?.billingInfo?.streetAddress);
        setCity(user?.billingInfo?.city);
        setZipCode(user?.billingInfo?.zipCode);
      } else if (user) {
        setCountry(user?.country || "");
        onCountryChange(user?.country || "", countries);
        setCountryState(user?.state || "");
        const name = user?.fullName.split(" ");
        setFullName(user?.fullName || "");
        setEmail(user?.email || "");
        setPhone(user?.phone || "");
      }
    }
  }, [isFirstEffectDone]);

  const onCountryChange = (selectedCountryId: string, countryList: any[]) => {
    setCountryState("");
    setStateList([]);

    let selectedCountry: any = countryList.find(
      (country: any) =>
        country.label.toString() === selectedCountryId.toString()
    );

    if (selectedCountry) {
      setCountry(selectedCountryId);

      let statesListFromLocalStorage = localStorage.getItem("states");
      if (statesListFromLocalStorage) {
        let statesList = JSON.parse(statesListFromLocalStorage);

        let countryStatesList = statesList.filter((state: any) => {
          return state.country_id === +selectedCountry.value;
        });

        let mappedCountryStatesList = countryStatesList.map((state: any) => {
          return { value: state.id, label: state.name };
        });

        setStateList(mappedCountryStatesList);
      }
    }
  };

  const onFriendCountryChange = (selectedCountryId: string) => {
    setFriendCountryState("");
    setFriendStateList([]);

    let selectedCountry: any = countryList.find(
      (country: any) =>
        country.label.toString() === selectedCountryId.toString()
    );

    if (selectedCountry) {
      setFriendCountry(selectedCountryId);

      let statesListFromLocalStorage = localStorage.getItem("states");
      if (statesListFromLocalStorage) {
        let statesList = JSON.parse(statesListFromLocalStorage);

        let countryStatesList = statesList.filter((state: any) => {
          return state.country_id === +selectedCountry.value;
        });

        let mappedCountryStatesList = countryStatesList.map((state: any) => {
          return { value: state.id, label: state.name };
        });

        setFriendStateList(mappedCountryStatesList);
      }
    }
  };

  const onStateChange = (stateId: string) => {
    let statesListFromLocalStorage = localStorage.getItem("states");
    if (statesListFromLocalStorage) {
      let statesList = JSON.parse(statesListFromLocalStorage);
      let selectedState = statesList.find((state: any) => {
        return state.name === stateId;
      });

      setCountryState(selectedState.name);
    }
  };

  const onFriendStateChange = (stateId: string) => {
    let statesListFromLocalStorage = localStorage.getItem("states");
    if (statesListFromLocalStorage) {
      let statesList = JSON.parse(statesListFromLocalStorage);
      let selectedState = statesList.find((state: any) => {
        return state.name === stateId;
      });

      setFriendCountryState(selectedState.name);
    }
  };

  const handleFirstSumbit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (agree) {
      if (save) {
        try {
          await userStore.updateProfile(user?.id, {
            billingInfo: {
              fullName,
              email,
              phone,
              country,
              state: countryState,
              streetAddress,
              city,
              zipCode,
            },
          });
          setStatus(2);
        } catch (error) {
          console.log(error);
        }
      } else {
        setStatus(2);
      }
    }
  };

  return (
    <form className={styles.payment_form} onSubmit={handleFirstSumbit}>
      <div className={styles.payment_form_container}>
        <div className={styles.payment_progress}>
          <ProgressItem
            active={status === 1 || status === 2}
            count={1}
            text={t("paymentForm.progress.billingInfo")}
          />
          <hr />
          <ProgressItem
            active={status === 2}
            count={2}
            text={t("paymentForm.progress.payment")}
          />
          <hr />
          <ProgressItem count={3} text={t("paymentForm.progress.review")} />
        </div>

        {status === 1 ? (
          <>
            <Input
              fullWidth
              required
              label={t("paymentForm.fullName")}
              value={fullName}
              validation={validateName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <Input
              required
              label={t("paymentForm.email")}
              value={email}
              type="email"
              validation={validateEmail}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Input
              label={t("paymentForm.streetAddress")}
              value={streetAddress}
              onChange={(e) => setStreetAddress(e.target.value)}
            />

            <div className={styles.payment_form_row}>
              <div className="w-full flex justify-start items-center space-x-2">
                <Select
                  fullWidth
                  label={t("paymentForm.country")}
                  value={country}
                  options={countryList}
                  onChange={(e) => onCountryChange(e.target.value, countryList)}
                />
                <Select
                  fullWidth
                  label={t("paymentForm.stateProvince")}
                  value={countryState}
                  options={stateList}
                  onChange={(e) => onStateChange(e.target.value)}
                />
              </div>
              <Input
                fullWidth
                label={t("paymentForm.city")}
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>

            <div className={styles.payment_form_row}>
              <div style={{ flex: 1 }}>
                <Input
                  fullWidth
                  label={t("paymentForm.zipCode")}
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <Input
                  fullWidth
                  label={t("paymentForm.phone")}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div hidden>
                <input
                  id="ClassName"
                  value={courseTitle}
                  placeholder="Class Name"
                  type="text"
                />
              </div>
            </div>
            <div className={styles.checkbox_container}>
              <input
                type="checkbox"
                id="save"
                name="save"
                value="Bike"
                checked={save}
                onChange={() => setSave(!save)}
              />
              <label htmlFor="save">{t("paymentForm.saveMyInfo")}</label>
            </div>
            <button type="submit" ref={submitRef} style={{ display: "none" }}>
              sub
            </button>
          </>
        ) : (
          <PaymentMethods
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
        )}
      </div>
      {friendAdded && status === 1 && (
        <div className={styles.payment_form_container}>
          <div className={styles.payment_form_row}>
            <div style={{ flex: 1 }}>
              <Input
                fullWidth
                required
                label="First Name"
                value={friendFirstName}
                onChange={(e) => setFriendFirstName(e.target.value)}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Input
                fullWidth
                required
                label="Last Name"
                value={friendLastName}
                onChange={(e) => setFriendLastName(e.target.value)}
              />
            </div>
          </div>

          <Input
            required
            label="Email"
            type="email"
            value={friendEmail}
            onChange={(e) => setFriendEmail(e.target.value)}
          />

          <Input
            required
            label="Street address"
            value={friendStreetAddress}
            onChange={(e) => setFriendStreetAddress(e.target.value)}
          />

          <div className={styles.payment_form_row}>
            <div className="w-full flex justify-start items-center space-x-2">
              <Select
                fullWidth
                required
                label="Country"
                value={friendCountry}
                options={countryList}
                onChange={(e) => onFriendCountryChange(e.target.value)}
              />
              <Select
                fullWidth
                required
                label="State/Province"
                value={friendCountryState}
                options={friendStateList}
                onChange={(e) => onFriendStateChange(e.target.value)}
              />
            </div>
            <Input
              fullWidth
              required
              label="City"
              value={friendCity}
              onChange={(e) => setFriendCity(e.target.value)}
            />
          </div>

          <div className={styles.payment_form_row}>
            <div style={{ flex: 1 }}>
              <Input
                fullWidth
                required
                label="Zip/Postal Code"
                value={friendZipCode}
                onChange={(e) => setFriendZipCode(e.target.value)}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Input
                fullWidth
                required
                label="Phone"
                value={friendPhone}
                onChange={(e) => setFriendPhone(e.target.value)}
              />
            </div>
          </div>
          {/* <div className={styles.checkbox_container}>
            <input
              type="checkbox"
              id="friendSave"
              name="friendSave"
              value="Bike"
              checked={friendSave}
              onChange={() => setFriendSave(!friendSave)}
            />
            <label htmlFor="friendSave">Save my informations</label>
          </div> */}
        </div>
      )}
    </form>
  );
}
