import { useEffect, useState } from "react";
import env from "../config/env";

let isScriptLoaded = false;
const apiKey = env.GOOGLE_MAPS_API_KEY!;

export function useGoogleMaps() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      return;
    }

    if (isScriptLoaded) {
      setIsLoaded(true);
      return;
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.onload = () => {
      isScriptLoaded = true; // Prevent re-loading
      setIsLoaded(true);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up if needed
    };
  }, []);

  return isLoaded;
}
