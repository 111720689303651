import styles from "./faqs.module.css";
import FaqItem from "./FaqItem";
import { faqs } from "../../../../constants";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export default function Faqs() {
  const { t } = useTranslation();

  const faqs: any = t("faqs", { returnObjects: true });

  const questions = [
    {
      title: "Are your courses certified?",
      content: (
        <p>
          Yes, all our courses are certified, with accreditations from industry
          leaders or recognized curriculum providers.
        </p>
      ),
    },
    {
      title: "Where can I take the training?",
      content: (
        <p>
          You can attend our training classes either <b>Live Online</b> (Zoom,
          Microsoft Teams, Google Meet, etc) or <b>In-Person</b> at classroom
          spaces available across your city.
        </p>
      ),
    },
    {
      title: "Who are your trainers?",
      content: (
        <>
          <p>
            Our trainers are industry leaders such as{" "}
            <b>CEOs, Directors, Qualified Engineers, and many more</b> who are
            also certified subject matter experts.
          </p>
          <p>
            Each instructor is carefully selected based on ethics, teaching
            skills, and expertise.
          </p>
        </>
      ),
    },
    {
      title: "What are the conditions of access to your classes?",
      content: (
        <>
          <p>
            The conditions and requirements to access each classes are detailed
            on the course pages associated with the particular class. If none is
            provided, then the class is most likely open for everyone.
          </p>
          <p>
            Feel free to contact us if you need further information by reaching
            out via email.
          </p>
          <a
            href="mailto:product@classx.tech"
            className="underline text-primary-paradise-pink-500"
          >
            product@classx.tech
          </a>
        </>
      ),
    },
    {
      title: "How can I finance my training?",
      content: (
        <>
          <p>Here are some flexible ways to finance your training:</p>
          <p>
            <b>1. Klarna:</b> Spread the cost by breaking your payment into
            smaller manageable installments with Klarna, making it easier to fit
            into your budget.
          </p>
          <p>
            <b>2. Scholarships:</b> Explore scholarship opportunities to help
            cover the cost of your training.
          </p>
          <p>
            <b>3. Employer Sponsorship:</b> Planning to apply these skills to
            your current job? Consider requesting your employer to sponsor your
            training investment.
          </p>
        </>
      ),
    },
  ];

  const [isExpanded, setIsExpanded] = useState<boolean[]>(
    new Array(questions.length).fill(false)
  );

  const toggleExpand = (index: number) => {
    setIsExpanded((prev) => {
      return prev.map((item, i) => (i === index ? !item : false));
    });
  };

  return (
    <div
      className="flex flex-col gap-6 sm:gap[60px] lg:gap-[50px] py-5 sm:py-5 lg:py-10 "
      id="faqs"
    >
      <div className="flex flex-col items-center gap-1 sm:gap-1.5 text-center">
        <h2 className={styles.colored_title}>Frequently Asked Questions</h2>
        <span className="text-body-medium font-body-medium sm:font-body-large sm:text-body-large">
          Some frequently asked questions about this course
        </span>
      </div>

      <div className="max-w-[800px] mx-auto my-auto">
      {questions.map((question, index) => (
          <div
            key={index}
            className={`border-b-2 border-primary-gray-100 hover:border-primary-paradise-pink-500 hover:text-primary-paradise-pink-500 cursor-pointer transition-colors duration-100 ${
              isExpanded[index]
                ? "bg-primary-paradise-pink-50 border-primary-paradise-pink-500 text-primary-paradise-pink-500"
                : "bg-primary-gray-50 hover:bg-primary-paradise-pink-50"
            }`}
            onClick={() => toggleExpand(index)}
          >
            <div className="flex gap-3.5 sm:gap-9 p-3.5 sm:px-6 sm:py-5">
              <img
                width={24}
                height={24}
                src={
                  isExpanded[index]
                    ? "/images/icons/minus-primary.svg"
                    : "/images/icons/plus-primary.svg"
                }
                alt="Arrow down"
              />
              <h4 className="text-body-small font-body-small-bold sm:text-body-large sm:font-body-large">
                {question.title}
              </h4>
            </div>

            <div
              className={`flex flex-col gap-3.5 text-body-small sm:text-body-medium text-primary-paradise-pink-850 pl-[52px] sm:pl-[84px] pr-2.5 overflow-hidden transition-all duration-[0.1s] ease-[cubic-bezier(0.34, 0.32, 0.43, 0.9)] ${
                isExpanded[index] ? "max-h-screen pb-5 sm:pb-8" : "max-h-0 pb-0"
              }`}
            >
              {question.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
