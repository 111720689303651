import { useStore } from "../../../../store";
import { observer } from "mobx-react";
import env from "../../../../config/env";
import { useEffect } from "react";
import React from "react";

function AboutCreator() {
    const { courseStore } = useStore();
    const course = courseStore.courseDetails;
    const courseId = course?.courseId;
    const [instructorData, setInstructorData] = React.useState<any>(null);
    console.log(course,"course")

    const instructorId = course?.tutors?.id;
    console.log(instructorId,"instructorId")

    useEffect(() => {
      if (instructorId) {
          (async () => {
              try {
                  const response = await courseStore.getInstructorById(instructorId);
                  setInstructorData(response);
              } catch (error) {
                  console.error("Error fetching instructor data:", error);
                  setInstructorData(null); 
              }
          })();
      }
  }, [instructorId]);

    console.log(instructorData,"instructorData")

    return (
        <div className="flex flex-col gap-4 w-full items-center" id="about-creator">
            <h3 className="body-large-bold">About the Instructor</h3>
            <div className="flex flex-col gap-[22px] sm:gap-[30px] p-4 sm:p-[50px] border border-primary-gray-200 rounded-[36px] sm:rounded[54px] bg-primary-gray-50 max-w-[800px]">
                <div className="flex items-center gap-4 sm:gap-[26px] lg:gap-[30px]">
                    <img
                        alt=""
                        className="hidden lg:block size-[186px] rounded-full"
                        src={
                            instructorData?.attachmentPhoto?.name 
                                ? `${env.IMAGE_API}${instructorData.attachmentPhoto.name}` 
                                : "/images/default-user.svg"
                        }
                    />
                    <div className="flex flex-col gap-[22px]">
                        <div className="flex items-center gap-4 sm:gap[26px] lg:gap-0">
                            <img
                                alt=""
                                className="block lg:hidden size-[76px] sm:size-[116px] rounded-full"
                                src={
                                    instructorData?.attachmentPhoto?.name
                                        ? `${env.IMAGE_API}${instructorData.attachmentPhoto.name}`
                                        : "/images/default-user.svg"
                                }
                            />
                            <div className="flex flex-col sm:gap-1.5">
                                <h3 className="text-body-large font-body-large sm:text-heading sm:font-heading-medium text-primary-purple-850">
                                    {instructorData?.fullName}
                                </h3>
                                {instructorData?.fullName && (
                                    <span className="text-caption-text font-caption-text-bold sm:text-body-small sm:font-body-small-bold text-primary-purple-800">
                                        {instructorData.fullName}
                                    </span>
                                )}
                            </div>
                        </div>
                        <p className="body-small pl-4 border-l-[3px] h-fit border-primary-purple-500 text-primary-paradise-pink-800">
                            {instructorData?.description}
                        </p>
                    </div>
                </div>
                <div className="flex flex-col sm:block gap-2 sm:gap-4">
                    <div className="flex gap-2 sm:gap-4">
                        <div className="flex flex-col items-center gap-2 py-3.5 w-full border border-primary-purple-100 rounded-2xl bg-primary-purple-50">
                            <img
                                width={20}
                                height={20}
                                src="/images/icons/star-purple.svg"
                                alt=""
                            />
                            <span className="heading-4 flex flex-col items-center gap-0.5">
                                {instructorData?.rating?.toLocaleString() || 0}
                                <span className="caption-text text-primary-purple-850">
                                    Reviews
                                </span>
                            </span>
                        </div>
                        <div className="hidden sm:flex flex-col items-center gap-2 py-3.5 w-full border border-primary-purple-100 rounded-2xl bg-primary-purple-50">
                            <img
                                width={20}
                                height={20}
                                src="/images/icons/user-purple.svg"
                                alt=""
                            />
                            <span className="heading-4 flex flex-col items-center gap-0.5">
                                {instructorData?.studentsCount?.toLocaleString() || 0}
                                <span className="caption-text text-primary-purple-850">
                                    Students
                                </span>
                            </span>
                        </div>
                        <div className="flex flex-col items-center gap-2 py-3.5 w-full border border-primary-purple-100 rounded-2xl bg-primary-purple-50">
                            <img
                                alt=""
                                width={20}
                                height={20}
                                src="/images/icons/course-purple.svg"
                            />
                            <span className="heading-4 flex flex-col items-center gap-0.5">
                                {instructorData?.coursesCount?.toLocaleString() || 0}
                                <span className="caption-text text-primary-purple-850">
                                    Courses
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AboutCreator);