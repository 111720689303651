import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPublicRooms } from "src/store/Classroom";

import styles from "./becomeHost.module.css";
import { useTranslation } from "react-i18next";

import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";
import env from "../../../../config/env";

const BecomeHost: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [locationsList, setLocationsList] = useState<any[]>([]);

  useEffect(() => {
    getPublicRooms().then((response: any) => {
      if (response !== null) {
        if (response.length > 0) {
          let roomsLocation = response.map((room: any) => {
            return room.location;
          });

          setLocationsList(roomsLocation);
        } else {
          setLocationsList(locations);
        }
      } else {
        setLocationsList(locations);
      }
    });
  }, [setLocationsList]);

  const centerPosition = { lat: 37.7749, lng: -122.4194 };

  const locations = [
    { lat: 36.8133, lng: 10.1658 },
    { lat: 36.7256, lng: 10.4113 },
    { lat: 36.8665, lng: 10.3227 },
    { lat: 36.8333, lng: 9.9173 },
    { lat: 36.936, lng: 9.785 },
    { lat: 36.6557, lng: 10.2236 },
    { lat: 36.4563, lng: 10.731 },
    { lat: 36.5842, lng: 10.0522 },
    { lat: 36.4333, lng: 9.5833 },
    { lat: 36.7254, lng: 9.2714 },
    { lat: 36.5557, lng: 9.8123 },
    { lat: 36.7178, lng: 10.2703 },
    { lat: 36.4, lng: 9.9 },
    { lat: 36.87, lng: 10.09 },
    { lat: 36.7833, lng: 10.6667 },
  ];

  const redirectToHostUpgrade = () => {
    let token = localStorage.getItem("token");
    token ? navigate("/hostUpgrade") : navigate("/register");
  };

  return (
    <div>
      <div className={styles.background}></div>
      <div className={styles.container}>
        <h3 className="text-body-large font-body-large-bold sm:text-heading-large ">
          {t("becomeHost.heading.part1")}{" "}
          <span className={styles.colored_span}>
            {" "}
            {t("becomeHost.heading.part2")}
          </span>{" "}
          <br /> {t("becomeHost.heading.part3")}
        </h3>

        <p className="text-body-medium font-body-small sm:text-body-medium sm:font-body-medium mb-2.5">
          {t("becomeHost.subheading")} <br className="hidden sm:block" />{" "}
          {t("becomeHost.subheading2")}
          <b> {t("becomeHost.subheading3")}</b>
        </p>

        <APIProvider apiKey={env.GOOGLE_MAPS_API_KEY}>
          <div className="rounded-2xl overflow-hidden w-full h-[400px]">
            <Map
              defaultZoom={11}
              defaultCenter={centerPosition}
              mapId={"e92cc09a7d8fb0e9"}
            >
              {locationsList.map((location, index) => (
                <AdvancedMarker key={index} position={location}>
                  <div
                    style={{
                      width: "17px",
                      height: "17px",
                      backgroundColor: "var(--Primary-Purple-500)",
                      borderRadius: "50%",
                      border: "4px solid white",
                      boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                    }}
                  />
                </AdvancedMarker>
              ))}
            </Map>
          </div>
        </APIProvider>

        {/* <img src="/images/hosts-maps.svg" /> */}

        <div className="flex gap-2.5">
          <a
            href="https://community.classx.tech/list-your-space/"
            target="_blank"
            className={
              styles.secondary_button +
              " large-button px-8 py-3 text-primary-purple-600 bg-primary-purple-100 rounded-xl"
            }
          >
            {t("becomeHost.learnMore")}
          </a>
          <button
            className={
              styles.primary_button +
              " large-button px-8 py-3 text-primary-purple-50 bg-primary-purple-500 rounded-xl"
            }
            onClick={redirectToHostUpgrade}
          >
            {t("becomeHost.becomeAHost")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BecomeHost;
